import React, { useMemo } from "react"

import { formatMonthDayYearTime, formatYearMonthTime } from "../../utils"

import "./styles.css"

type ResourcePostType =
  | "podcast"
  | "article"
  | "award"
  | "collateral"
  | "whitepaper"
  | "case study"
  | "webinar"

interface ResourcePostProps {
  coverSrc: string
  logoSrc: string
  src: string
  title: string
  type?: ResourcePostType
  content?: string
  logoType?: "medium" | "large"
  author?: string
  customDate?: Date | number | string
  lengthInMinutes?: number | string
  createdAt?: Date | number
  subText?: string
}

// const contentTypeUnit: Record<ResourcePostType, string> = {
//   addition: "Mins read",
//   podcast: "Mins",
//   post: "Mins",
// }

// const contentPrefix: Record<ResourcePostType, string> = {
//   addition: "Added on",
//   podcast: "Posted on",
//   post: "Posted on",
// }

const ResourcePost: React.FC<ResourcePostProps> = ({
  coverSrc,
  logoSrc,
  src,
  title,
  type = "post",
  content,
  logoType = "medium",
  author,
  customDate,
  createdAt,
  lengthInMinutes,
  subText,
}) => {
  const postInfo = useMemo(
    () =>
      [
        type &&
          `<strong style="color:var(--primary-color);">${type
            .charAt(0)
            .toUpperCase()}${type.substring(1)}</strong>`,
        subText && `<strong>${subText}</strong>`,
        createdAt && `<strong>${formatYearMonthTime(createdAt)}</strong>`,
        // lengthInMinutes && `${lengthInMinutes} ${contentTypeUnit[type]}`,
      ]
        .filter(Boolean)
        .join(" / "),
    [type, subText, createdAt]
  )

  return (
    <div className="app-resource-post">
      {/* <div style={{ textAlignLast: "center" }}> */}
      <div style={{ height: "40%", textAlign: "center" }}>
        <img
          className={`resource-cover`}
          src={coverSrc}
          alt={`${author || "post"} logo`}
        />
      </div>
      {/* </div> */}
      <div
        style={{
          // margin: 13,
          display: "flex",
          flexDirection: "column",
          height: 270,
        }}
      >
        <p
          style={{ fontSize: 14 }}
          dangerouslySetInnerHTML={{ __html: postInfo }}
        ></p>
        <h3>{title}</h3>

        <p className="resource-content">{content}</p>
        <div style={{ alignSelf: "center", marginTop: "auto" }}>
          <a
            href={src}
            target={
              type !== "whitepapers" && type !== "webinars" ? "_blank" : ""
            }
          >
            Read more
            <img src="/img/arrow-right.svg" alt="arrow" width={16} style={{ height: 16 }} />
          </a>
        </div>
      </div>
    </div>
  )
}

export { ResourcePost }
